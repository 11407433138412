// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1IRM{padding:3.2rem 0;display:flex;flex-wrap:wrap}@media (min-width:62em){._1IRM{padding:5.8rem 0}}._1IRM .zDiZ{max-width:100%}@media (min-width:62em){._1IRM .zDiZ{max-width:390px}}._1IRM ._2C4X{padding-bottom:8px}._1IRM ._2C4X p{margin:.8rem 0}._1IRM .PPgY{display:flex;align-items:center}._1IRM ._1thb{width:100%;max-width:390px;margin:1.6rem auto;text-align:center}._1IRM ._2jdD{margin:0 auto;display:block}._1IRM ._2Mwo{margin:2.4rem 0 0;font-weight:400;display:block}._1IRM ._3mC3{margin:1.6rem 0 0;display:block}", ""]);
// Exports
exports.locals = {
	"columnBanner": "_1IRM",
	"content": "zDiZ",
	"text": "_2C4X",
	"column": "PPgY",
	"figure": "_1thb",
	"image": "_2jdD",
	"figcaption": "_2Mwo",
	"notes": "_3mC3"
};
module.exports = exports;
